<template>
  <div class="bodys">
    <Header></Header>
    <Banner v-if="audit_type==2" :title="'为什么需要您进行企业认证'" :memo="'在八爪灵工，发布任务和开具发票 均需要完成企业实名验证，然后才能使用和享受服务。'"></Banner>
    <Banner v-if="audit_type==1" :title="'为什么需要您进行个人实名认证'" :memo="'在八爪灵工，大多数服务（投标任务、报酬发放等）均需要完成个人实名验证，</br> 然后才能使用和享受服务。'"></Banner>

    <el-card shadow="never" class="main-page">
      <CompanyUserAuth v-if="audit_type==2" :is-only-add="false" @auditBackData="auditBackData" ></CompanyUserAuth>
      <RealNameUserAuth v-if="audit_type==1" :is-only-add="false" @auditBackData="auditBackData"></RealNameUserAuth>
    </el-card>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from '@/components/Banner';
import CompanyUserAuth from "@/components/UserAuth/company";
import RealNameUserAuth from "@/components/UserAuth/realname";
export default {
  name: "selectauth",
  components: {
    Banner,
    CompanyUserAuth,
    RealNameUserAuth
  },
  data() {
    return {
      auditTypes: {
        real_name: 1,
        company: 2
      },
      audit_type: 1,
      task_id: 0,
    }
  },
  mounted() {
    this.audit_type = this.$route.params.type
    this.task_id = this.$route.params.task_id
  },
  methods: {
    auditBackData( data ){
      // 可以不用不能没有的方法
    }
  }
}
</script>

<style scoped>
header {
  margin-bottom: unset;
}
.main-page{
  margin: 0 auto;
  margin-top: -250px;
  width: 800px;
  background: #FFF;
}
/deep/ .el-card__body {
  padding: 20px 30px;
}
</style>
